<template xmlns="http://www.w3.org/1999/html">
  <div class="Cargando">
    <div v-if="loading" :class="['modal_cargando', { 'show': loading }]"
         @transitionend="onTransitionEnd">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de Categorías
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Categoría" aria-label="Recipient's username"
                       aria-describedby="button-addon2">
              </div>
            </div>
            <div class="col-9 text-end">
              <button @click="openModal" class="btn btn-dark align-content-end" type="submit">Nueva Categoría</button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Imagen</th>
                <th>Fecha</th>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="categoria in categoriaPerName" :key="categoria.id">
                <td class="text-sm font-weight-normal" :class="categoria.isActive?'':'text-danger'">
                  <div class="ms-3"><img class="rounded-img img-categoria"
                                         :src="this.$store.state.store_url+categoria.image"></div>
                </td>
                <td class="text-sm font-weight-normal" :class="categoria.isActive?'':'text-danger'">
                  <div class="ms-3">{{ categoria.fecha }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="categoria.isActive?'':'text-danger'">
                  <div class="ms-3">{{ categoria.name }}</div>
                </td>
                <td class="text-sm font-weight-normal" :class="categoria.isActive?'':'text-danger'">
                  <div class="ms-3">{{ categoria.isActive ? "Activada" : "Desactivada" }}</div>
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button v-if="categoria.isActive" @click="DesactiveCategoria(categoria.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Desactivar Categoría" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">block</i></button>
                    <button v-else @click="ActiveCategoria(categoria.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Activar Categoría" data-container="body" data-animation="true"
                            class="btn btn-warning p-1 ms-1">
                      <i class="material-icons opacity-10">check_circle</i></button>
                    <button v-if="categoria.visible" @click="OcultarCategoria(categoria.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Ocultar categría del Portal Web" data-container="body"
                            data-animation="true" class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <button v-else @click="MostrarCategoria(categoria.id)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Mostrar categría en el Portal Web" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">visibility_off</i></button>
                    <button
                      @click="openUpdate(categoria.name, categoria.id, categoria.maletasSm,
                       categoria.transmision, categoria.lugar, categoria.personas, categoria.maletas, categoria.descripcion)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Editar Categoría" data-container="body" data-animation="true"
                      class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">edit</i></button>
                    <button
                      @click="openImagen(categoria.image, categoria.id)"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Agregar o Actualizar Imagen de Categoría" data-container="body" data-animation="true"
                      class="btn btn-secondary p-1 ms-1">
                      <i class="material-icons opacity-10">add_photo_alternate</i></button>
                    <button v-if="tienePermiso('gestionar_tarifas')"
                            @click="openTarifas(categoria)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Agregar o Actualizar Tarifas de Categoría" data-container="body"
                            data-animation="true"
                            class="btn btn-dark p-1 ms-1">
                      <i class="material-icons opacity-10">attach_money</i></button>
                    <button @click="VerVehiculos(categoria)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Ver vehículos" data-container="body" data-animation="true"
                            class="btn btn-primary p-1 ms-1">
                      <img
                        src="http://issyrental-store-qa.s3.us-east-1.amazonaws.com/Administracion/iconos/directions_car_white_24dp.svg">
                    </button>
                    <button @click="DeleteCategoria(categoria.id)" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Eliminar Categoría" data-container="body" data-animation="true"
                            class="btn btn-danger p-1 ms-1">
                      <i class="material-icons opacity-10">delete</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal NuevaCategoria">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva Categoría<i @click="closeModal"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control mb-2"
                   placeholder="Escriba el nombre de una Categoría" type="text">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Cantidad de pasajeros</label>
            <input v-model="personas" class="form-control mb-2"
                   type="number" min="1">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Transmisión</label>
            <select class="form-select mb-2" v-model="transmision">
              <option value="" selected disabled>Seleccione un tipo de transmisión</option>
              <option value="Manual">Manual</option>
              <option value="Automática">Automática</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Lugar donde puede transitar</label>
            <select class="form-select mb-2" v-model="lugar">
              <option value="" selected disabled>Seleccione el lugar donde puede transitar</option>
              <option value="México">México</option>
              <option value="USA">USA</option>
              <option value="México y USA">México y USA</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Cantidad de maletas grandes</label>
            <input v-model="maletas" class="form-control mb-2"
                   type="number" min="1">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Cantidad de maletas pequeñas</label>
            <input v-model="maletasSm" class="form-control mb-2"
                   min="1" type="number">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Seleccione el ícono blanco</label>
            <input type="file" @change="onIconoW" class="form-control">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Seleccione el ícono negro</label>
            <input type="file" @change="onIconoB" class="form-control mb-3">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label class="form-label">Descripción</label>
            <input v-model="descripcion" class="form-control mb-2"
                   placeholder="Escriba la descripción" type="text">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div v-show="error" class="text-danger mt-2 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar los campos</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-start">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
            <button @click="addCategoria" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Tarifas">
    <div v-if="showTarifas" :class="['modal', { 'show': showTarifas }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Agregar o Modificar Tarifas<i
            @click="closeTarifas"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>

        <div class="row mb-2">
          <h6>Temporada alta</h6>
          <div class="col-lg-6 col-lx-6 col-md-6 col-sm-12 col-xs-12">
            <label class="form-label">Tarifa de cobertura total</label>
            <input v-model="total_alta" class="form-control border-bottom border-dark p-2 mb-2"
                   type="number" min="1">
          </div>
          <div class="col-lg-6 col-lx-6 col-md-6 col-sm-12 col-xs-12">
            <label class="form-label">Tarifa de cobertura tradicional</label>
            <input v-model="tradicional_alta" class="form-control border-bottom border-dark p-2"
                   min="1" type="number">
          </div>
        </div>

        <div class="row mb-3">
          <h6>Temporada baja</h6>
          <div class="col-lg-6 col-lx-6 col-md-6 col-sm-12 col-xs-12">
            <label class="form-label">Tarifa de cobertura total</label>
            <input v-model="total_baja" class="form-control border-bottom border-dark p-2 mb-2"
                   type="number" min="1">
          </div>
          <div class="col-lg-6 col-lx-6 col-md-6 col-sm-12 col-xs-12">
            <label class="form-label">Tarifa de cobertura tradicional</label>
            <input v-model="tradicional_baja" class="form-control border-bottom border-dark p-2"
                   min="1" type="number">
          </div>
        </div>
        <div class="row">
          <div v-show="error" class="text-danger mt-2 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
            <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
              <p class="text-dark">Debe llenar los campos</p></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeTarifas" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Tarifas" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal UpdateCategoria">
    <div v-if="UpdateModal" :class="['modal', { 'show': UpdateModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Editar Categoría<i @click="closeUpdate"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label class="form-label">Nombre</label>
            <input v-model="name" class="form-control mb-2"
                   placeholder="Escriba el nombre de una Categoría" type="text">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label class="form-label">Cantidad de pasajeros</label>
            <input v-model="personas" class="form-control mb-2"
                   type="number" min="1">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label class="form-label">Transmisión</label>
            <select class="form-select mb-2" v-model="transmision">
              <option value="" selected disabled>Seleccione un tipo de transmisión</option>
              <option value="Manual">Manual</option>
              <option value="Automática">Automática</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label class="form-label">Lugar donde puede transitar</label>
            <select class="form-select mb-2" v-model="lugar">
              <option value="" selected disabled>Seleccione el lugar donde puede transitar</option>
              <option value="México">México</option>
              <option value="USA">USA</option>
              <option value="México y USA">México y USA</option>
            </select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label class="form-label">Cantidad de maletas grandes</label>
            <input v-model="maletas" class="form-control mb-2"
                   type="number" min="1">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label class="form-label">Cantidad de maletas pequeñas</label>
            <input v-model="maletasSm" class="form-control mb-2"
                   min="1" type="number">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Seleccione el ícono blanco</label>
            <input type="file" @change="onIconoW" class="form-control">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <label class="form-label">Seleccione el ícono negro</label>
            <input type="file" @change="onIconoB" class="form-control mb-3">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
            <label class="form-label">Descripción</label>
            <input v-model="descripcion" class="form-control mb-2"
                   placeholder="Escriba la descripción" type="text">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
            <div v-show="error" class="text-danger mt-2 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar los campos</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="UpdateCategoria" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal NuevaImagen">
    <div v-if="ImageModal" :class="['modal', { 'show': ImageModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Nueva Imagen de Categoría<i
            @click="closeImageModal"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="form-label">Seleccione una Imagen</label>
            <input type="file" class="form-control mb-3 p-2 border-dark border-bottom" @change="onImagenSeleccionada">
            <img class="img-fluid" :src="preview" style="height: 250px">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe seleccionar una imagen</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeImageModal" class="btn btn-secondary" type="button">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="guardarImagen" class="btn btn-dark" type="submit">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { get_list_categorie, update_categorie } from "@/actions";


const DESACTIVE = gql`
mutation DesactiveCategoria($id:ID!) {
    desactiveCategoria(id: $id) {
        success
    }
}`;
const ACTIVE = gql`
mutation ActiveCategoria($id:ID!) {
    activeCategoria(id: $id) {
        success
    }
}
`;
const OCULTAR = gql`
mutation OcultarCategoria($id:ID!) {
    ocultarCategoria(id: $id) {
        success
    }
}`;
const MOSTRAR = gql`
mutation MostrarCategoria($id:ID!) {
    mostrarCategoria(id: $id) {
        success
    }
}
`;
const INSERT = gql`
  mutation($name:String!, $maletas:Decimal!, $maletasSm:Decimal!, $personas:Decimal!,
  $transmision:String!,$lugar:String!, $descripcion:String!){
  createCategoria(name:$name, maletasSm:$maletasSm, transmision:$transmision,
  lugar:$lugar, personas:$personas, maletas:$maletas, descripcion:$descripcion){
    success
    errors
    categoria{
      id
    }
  }
}`;

const DELETE = gql`
       mutation DeleteCategoria($id:ID!) {
          deleteCategoria(id: $id) {
              success
    }
}
`;
const TARIFAS = gql`
mutation Tarifas(
    $id: Int!
    $totalAlta: Decimal!
    $totalBaja: Decimal!
    $tradicionalAlta: Decimal!
    $tradicionalBaja: Decimal!
) {
    tarifas(
        id: $id
        totalAlta: $totalAlta
        totalBaja: $totalBaja
        tradicionalAlta: $tradicionalAlta
        tradicionalBaja: $tradicionalBaja
    ) {
        success
        errors
    }
}
`;
export default {
  name: "List_Categorias",
  components: { MaterialButton },
  data() {
    return {
      misearch: "",
      totales: [],
      tradicionales: [],
      categoriaPerName: [],
      showModal: false,
      UpdateModal: false,
      name: "",
      lugar: "",
      transmision: "",
      imagen: null,
      preview: null,
      iconW: null,
      iconWP: null,
      iconB: null,
      iconBP: null,
      maletas: 0,
      maletasSm: 0,
      personas: 0,
      categoriaid: 0,
      error: false,
      ImageModal: false,
      ImageUpdateModal: false,
      showTarifas: false,
      tradicional_baja: 0,
      total_baja: 0,
      tradicional_alta: 0,
      total_alta: 0,
      id: 0,
      descripcion: ""
    };
  },
  computed: {
    ...mapGetters(["tienePermiso"]),
    loading() {
      return this.$apollo.loading;
    }
  },
  created() {
    this.loadDatas();
  },
  methods: {
    async addCategoria() {
      if (this.name === "" || this.maletas === 0, this.maletasSm === 0
      || this.personas === 0 || this.transmision === 0 || this.lugar === 0 || this.iconW === null || this.iconB === null ||
      this.descripcion === "") {
        this.error = true;
        return false;
      }
      await this.$apollo
        .mutate({
          mutation: INSERT,
          variables: {
            name: this.name,
            transmision: this.transmision,
            lugar: this.lugar,
            personas: this.personas,
            maletas: this.maletas,
            maletasSm: this.maletasSm,
            descripcion: this.descripcion
          }
        }).then(response => {
          if (!response.data.createCategoria.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.createCategoria.errors
            });
            this.reset();
            this.closeModal();
            this.loadDatas();
          } else {
            const id = response.data.createCategoria.categoria.id;
            let data = new FormData();
            data.append("operations", `{"query": "mutation ($iconoW: Upload, $iconoB: Upload, $id: ID) {\\n  uploadFile(id: $id, iconoW: $iconoW, iconoB: $iconoB) {\\n    error  }\\n}","variables": {"id": ${id},"iconoW": null,"iconoB": null},"operationName": null}`);
            data.append("map", "{\"1\": [\"variables.iconoW\"],\"2\": [\"variables.iconoB\"]}");
            data.append("1", this.iconW, this.iconW.name);
            data.append("2", this.iconB, this.iconB.name);
            return axios.post(`${this.$store.state.url}graphql/`, data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }).then(resp => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Categoría creada"
              });
              this.reset();
              this.closeModal();
              this.loadDatas();
            }).catch(error => {
            });
          }
        });
    },
    async UpdateCategoria() {
      if (this.name === "" || this.maletas === 0, this.maletasSm === 0
      || this.personas === 0 || this.transmision === 0 || this.lugar === 0 ||
      this.descripcion === "") {
        this.error = true;
        return false;
      }
      update_categorie({
        name: this.name,
        transmision: this.transmision,
        lugar: this.lugar,
        personas: this.personas,
        maletas: this.maletas,
        maletasSm: this.maletasSm,
        id: this.categoriaid,
        descripcion: this.descripcion
      }).then(response => {
        console.log(response.data.data.updateCategoria);
        if (response.data.data.updateCategoria.success) {
          if (this.iconW !== null || this.iconB === null) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Categoría actualizada correctamente"
            });
            this.reset();
            this.closeUpdate();
            this.loadDatas();
          } else {
            let data = new FormData();
            data.append("operations", `{"query": "mutation ($iconoW: Upload, $iconoB: Upload, $id: ID) {\\n  uploadFile(id: $id, iconoW: $iconoW, iconoB: $iconoB) {\\n    error  }\\n}","variables": {"id": ${this.categoriaid},"iconoW": null,"iconoB": null},"operationName": null}`);
            data.append("map", "{\"1\": [\"variables.iconoW\"],\"2\": [\"variables.iconoB\"]}");
            data.append("1", this.iconW, this.iconW.name);
            data.append("2", this.iconB, this.iconB.name);
            return axios.post(`${this.$store.state.url}graphql/`, data, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }).then(resp => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Categoría actualizada correctamente"
              });
              this.reset();
              this.closeUpdate();
              this.loadDatas();
            }).catch(error => {
            });
          }

        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al actualizar la categoría"
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al actualizar la Categoría"
        });
        console.error("La mutación falló:", error);
      });
    },
    async DeleteCategoria(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, eliminar!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              id: id
            }
          });
          if (data.deleteCategoria.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Categoría eliminada"
            });
            this.loadDatas();
          }
        }
      });
    },
    async DesactiveCategoria(id) {
      const { data } = await this.$apollo.mutate({
        mutation: DESACTIVE,
        variables: {
          id: id
        }
      });
      if (data.desactiveCategoria.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "Categoría desactivada"
        });
        this.loadDatas();
      }
    },
    async ActiveCategoria(id) {
      const { data } = await this.$apollo.mutate({
        mutation: ACTIVE,
        variables: {
          id: id
        }
      });
      if (data.activeCategoria.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Categoría activada"
        });
        this.loadDatas();
      }
    },
    async OcultarCategoria(id) {
      const { data } = await this.$apollo.mutate({
        mutation: OCULTAR,
        variables: {
          id: id
        }
      });
      if (data.ocultarCategoria.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "warning",
          title: "La categoría no se mustra en el Portal Web"
        });
        this.loadDatas();
      }
    },
    async MostrarCategoria(id) {
      const { data } = await this.$apollo.mutate({
        mutation: MOSTRAR,
        variables: {
          id: id
        }
      });
      if (data.mostrarCategoria.success) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "La categría se muestra en el Portal Web"
        });
        this.loadDatas();
      }
    },
    openModal() {
      this.reset();
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openTarifas(categoria) {
      this.tradicional_baja = categoria.tradicionalBaja ? categoria.tradicionalBaja : 0;
      this.total_baja = categoria.totalBaja ? categoria.totalBaja : 0;
      this.tradicional_alta = categoria.tradicionalAlta ? categoria.tradicionalAlta : 0;
      this.total_alta = categoria.totalAlta ? categoria.totalAlta : 0;
      this.id = categoria.id;
      this.showTarifas = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openUpdate(name, id, maletasSm, transmision, lugar, personas, maletas, descripcion) {
      this.name = name;
      this.maletas = maletas;
      this.maletasSm = maletasSm;
      this.personas = personas;
      this.transmision = transmision;
      this.lugar = lugar;
      this.descripcion = descripcion;
      this.categoriaid = id;
      this.UpdateModal = true;
      document.body.classList.add("modal-open");
    },
    closeUpdate() {
      this.reset();
      this.UpdateModal = false;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.reset();
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    closeTarifas() {
      this.reset();
      this.showTarifas = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    openImagen(imagen, id) {
      if (imagen) {
        this.preview = this.$store.state.store_url + imagen;
      }
      this.categoriaid = id;
      this.ImageModal = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    guardarImagen() {
      let data = new FormData();
      data.append("operations", `{"query": "mutation ($file: Upload, $id: ID) {\\n  uploadImage(id: $id, file: $file) {\\n    error  }\\n}","variables": {"id": ${this.categoriaid},"file": null},"operationName": null}`);
      data.append("map", "{\"1\": [\"variables.file\"]}");
      data.append("1", this.imagen, this.imagen.name);
      return axios.post(`${this.$store.state.url}graphql/`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(resp => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "success",
          title: "Imagen guardada"
        });
        this.reset();
        this.closeImageModal();
        this.loadDatas();
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Error al subir la imagen"
        });
      });
    },
    loadDatas() {
      get_list_categorie({ name: this.misearch }).then(resp => {
        this.categoriaPerName = resp.data.data.categoriaPerName;
      });
    },
    closeImageModal() {
      this.reset();
      this.ImageModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    onImagenSeleccionada(event) {
      this.imagen = event.target.files[0];
      const file = event.target.files[0];
      this.preview = URL.createObjectURL(file);
    },
    onIconoW(event) {
      this.iconW = event.target.files[0];
    },
    onIconoB(event) {
      this.iconB = event.target.files[0];
    },
    async Tarifas() {
      await this.$apollo.mutate({
        mutation: TARIFAS,
        variables: {
          id: this.id,
          totalAlta: this.total_alta,
          totalBaja: this.total_baja,
          tradicionalAlta: this.tradicional_alta,
          tradicionalBaja: this.tradicional_baja
        }
      }).then(response => {
        if (response.data.tarifas.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Tarifas actualizadas"
          });
          this.closeTarifas();
          this.loadDatas();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.tarifas.error
          });
          this.closeTarifas();
          this.loadDatas();
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
        this.closeTarifas();
      });
    },
    next() {
      if (this.name === "" || this.precio_max === 0 || this.precio_min === 0 || this.maletas === 0
        || this.personas === 0 || this.transmision === 0 || this.lugar === 0) {
        this.error = true;
        return false;
      }
      this.showModal = false;
      this.ImageModal = true;
      this.error = false;
    },
    prev() {
      this.ImageModal = false;
      this.showModal = true;
      this.error = false;
    },
    reset() {
      this.name = "";
      this.tradicional_baja = 0;
      this.total_baja = 0;
      this.tradicional_alta = 0;
      this.total_alta = 0;
      this.id = 0;
      this.descripcion = "";
      this.transmision = "";
      this.lugar = "";
      this.file = null;
      this.imagen = null;
      this.preview = null;
      this.iconW = null;
      this.iconWP = null;
      this.iconB = null;
      this.iconBP = null;
      this.maletas = 0;
      this.maletasSm = 0;
      this.personas = 0;
      this.categoriaid = 0;
      this.error = false;
    },
    VerVehiculos(categoria) {
      this.$store.state.categoria_vehiculo = categoria;
      this.$router.push("/vehiculos/listado");
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.img-categoria {
  height: 60px;
  width: 90px;
  object-fit: cover;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 50%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {

  .modal-content {
    width: 100%;
    margin-top: 200px;
  }

}

.rounded-img {
  border-radius: 60px;
}
</style>
