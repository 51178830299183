import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Authorization"] = sessionStorage.getItem("token");

export const reloadHeader = () => {
  axios.defaults.headers.post["Authorization"] = sessionStorage.getItem("token");
};

export const getNotifications = (message, next) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `{
                    searchFirebaseNotifications(first: 10, after: "${next}", message_Icontains: "", firebase: false){
                        pageInfo{
                            endCursor
                            hasNextPage
                        }
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
    }
  );
};

export const readMessage = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation($id:Int!){
                    readNotification(id: $id){
                        info{
                            id
                            message
                            title
                            readClient
                            reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                        }
                   }
                 }`, variables: obj
    }
  );
};

export const getReservationsNoShow = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                descuento
                cortesia{
                id
                }
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                extraservicereserveSet {
                    id
                    description
                    price
                    daily
                }
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                vehiculoReservadoSet {
                    id
                    vehiculo {
                        id
                        noEcon
                        categoria {
                            id
                            name
                        }
                    }
                    reserva {
                        id
                        categoria {
                            id
                            name
                        }
                    }
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNoShowNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        after: $after
        first: $first
        before:""
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNoShowPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservations = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        before: $before
        after: $after
        first: $first
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                descuento
                cortesia{
                id
                }
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                extraservicereserveSet {
                    id
                    description
                    price
                    daily
                }
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                vehiculoReservadoSet {
                    id
                    vehiculo {
                        id
                        noEcon
                        categoria {
                            id
                            name
                        }
                    }
                    reserva {
                        id
                        categoria {
                            id
                            name
                        }
                    }
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $after: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        after: $after
        first: $first
        before:""
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                total
                email
                descuento
                cortesia{
                id
                }
                noShow
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                vehiculoReservadoSet {
                    id
                    vehiculo {
                        id
                        noEcon
                        categoria {
                            id
                            name
                        }
                    }
                    reserva {
                        id
                        categoria {
                            id
                            name
                        }
                    }
                }
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        before: $before
        first: $first
        after:""
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                total
                descuento
                cortesia{
                id
                }
                noShow
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                vehiculoReservadoSet {
                    id
                    vehiculo {
                        id
                        noEcon
                        categoria {
                            id
                            name
                        }
                    }
                    reserva {
                        id
                        categoria {
                            id
                            name
                        }
                    }
                }
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const CoberturasPerTarifaTotal = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query CoberturasPerTarifaTotal {
            coberturasPerTarifaTotal(name: "") {
                id
                cobertura
                isActive
            }
        }`
    }
  );
};
export const CoberturasPerTarifaTradicional = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query CoberturasPerTarifaTradicional {
    coberturasPerTarifaTradicional(name: "") {
        id
        cobertura
        isActive
    }
}`
    }
  );
};

export const getReservationsById = (id) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ReservaById($id:ID!) {
    reservaById(id: $id) {
                id
                total
                noShow
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                descuento
                cortesia{
                id
                }
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }`,
      variables: { id: id }
    }
  );
};
export const getNotificationsNotRead = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `{
                    searchFirebaseNotifications(readClient: false, firebase: false){
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
    }
  );
};
export const createExtraServiceToReserve = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation CreateServiceToReserve(
                $daily:Boolean!
                $description:String!
                $price:Decimal!
                $reserve:ID!
            ) {
                createServiceToReserve(
                    daily: $daily
                    description: $description
                    price: $price
                    reserve: $reserve
                ) {
                    success
                    error
                }
            }
            `, variables: obj
    }
  );
};
export const createExtraServiceToContract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation CreateServiceToContract(
                $contract:ID!
                $daily:Boolean!
                $description:String!
                $price:Decimal!
            ) {
                createServiceToContract(
                    contract: $contract
                    daily: $daily
                    description: $description
                    price: $price
                ) {
                    success
                    error
                }
            }`, variables: obj
    }
  );
};
export const serviceByContract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ServicioPorContrato(
                $idServicio:Int!
                $idContrato:String!
            ) {
                servicioPorContrato(idServicio: $idServicio, idContrato: $idContrato)
            }`, variables: obj
    }
  );
};
export const servicesPerName = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ServiciosPerName {
                serviciosPerName(name: "") {
                    id
                    isActive
                    name
                    diario
                    precio
                    icono
                }
            }`
    }
  );
};
export const titulares = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query TitularPerName {
                titularPerName(name: "") {
                    id
                    name
                    isActive
                }
            }`
    }
  );
};


export const getClients = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchClients(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchClients(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
                carnet
                licenciaFile
                pasaporte
                tipo {
                    id
                    name
                    isActive
                }
                ciudad
                estado
                pais
                licencia {
                    id
                    numero
                    vencimiento
                    permanente
                    estado
                    pais
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getClientsNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchClients(
    $name: String!
    $after: String!
    $first: Int!
) {
    searchClients(
        before: ""
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
                carnet
                licenciaFile
                pasaporte
                tipo {
                    id
                    name
                    isActive
                }
                ciudad
                estado
                pais
                licencia {
                    id
                    numero
                    vencimiento
                    permanente
                    estado
                    pais
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getClientsPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchClients(
    $name: String!
    $before: String!
    $first: Int!
) {
    searchClients(
        before: $before
        after: ""
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
                carnet
                licenciaFile
                pasaporte
                tipo {
                    id
                    name
                    isActive
                }
                ciudad
                estado
                pais
                licencia {
                    id
                    numero
                    vencimiento
                    permanente
                    estado
                    pais
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};

export const getContracts = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $before:String!
                $after:String!
                $first:Int!
                $estado:String!
                ) {
                searchContracts(
                    name:$name
                    before: $before
                    after: $after
                    first: $first
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                           id
                           extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                           estado
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            montoCombustible
                            descuento
                            cortesia {
                                id
                                name
                                descuento
                                isActive
                            }
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                capacidadTanke
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $after:String!
                $first:Int!
                $estado:String!
                ) {
                searchContracts(
                    name:$name
                    after: $after
                    first: $first
                    before:""
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                           id
                           extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                           estado
                            tipoCobertura
                            seguroRc
                            revisado
                            montoCombustible
                            tipoPago
                            fecha
                            descuento
                            cortesia {
                                id
                                name
                                descuento
                                isActive
                            }
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $before: String!
                $first: Int!
                $estado:String!
            ) {
                searchContracts(
                    name:$name
                    before: $before
                    after:""
                    first: $first
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                            id
                            extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                            estado
                            tipoCobertura
                            seguroRc
                            revisado
                            descuento
                            montoCombustible
                            cortesia {
                                id
                                name
                                descuento
                                isActive
                            }
                            tipoPago
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version {
                                    name
                                    modelo {
                                        name
                                        marca {
                                            name
                                        }
                                    }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};

export const getContractsOpen = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $before:String!
                $after:String!
                $first:Int!
                $estado:String!
                ) {
                searchContracts(
                    name:$name
                    before: $before
                    after: $after
                    first: $first
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                           id
                           extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                           estado
                           montoCombustible
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            descuento
                            cortesia {
                                id
                                name
                                descuento
                                isActive
                            }
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsOpenNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $after:String!
                $first:Int!
                $estado:String!
                ) {
                searchContracts(
                    name:$name
                    after: $after
                    first: $first
                    before:""
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                           id
                           descuento
                           extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                           estado
                            montoCombustible
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            fecha
                            cortesia {
                                id
                                name
                                descuento
                                isActive
                            }
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsOpenPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $before: String!
                $first: Int!
                $estado:String!
            ) {
                searchContracts(
                    name:$name
                    before: $before
                    after:""
                    first: $first
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                            id
                            descuento
                            extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                            estado
                            montoCombustible
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            cortesia {
                                id
                                name
                                descuento
                                isActive
                            }
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version {
                                    name
                                    modelo {
                                        name
                                        marca {
                                            name
                                        }
                                    }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};


export const getDisponiblesCar = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponibles(
                    categoria: $categoria
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                    after:""
                    before:""
                    first:100
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getDisponiblesCarDisponibilidad = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $before: String!
                $after: String!
                $first: Int!
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponiblesDisponibilidad(
                    categoria: $categoria
                    before: $before
                    after: $after
                    first: $first
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getDisponiblesCarDisponibilidadPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $before: String!
                $first: Int!
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponiblesDisponibilidad(
                    categoria: $categoria
                    before: $before
                    after:""
                    first: $first
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getDisponiblesCarDisponibilidadNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $after:String!
                $first:Int!
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponiblesDisponibilidad(
                    categoria: $categoria
                    after: $after
                    before:""
                    first: $first
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getCoberturasTotal = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CoberturasPerTarifaTotal {
          coberturasPerTarifaTotal(name: "") {
              id
              cobertura
              isActive
          }
      }`
    }
  );
};
export const getCoberturasTradicional = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CoberturasPerTarifaTradicional {
          coberturasPerTarifaTradicional(name: "") {
              id
              cobertura
              isActive
          }
      }`
    }
  );
};


export const getCategoriasDisponibles = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CategoriasDisponiblesAdmin($fechaRenta:DateTime!, $fechaRegreso:DateTime!) {
          categoriasDisponiblesAdmin(fechaSalida: $fechaRenta, fechaRegreso: $fechaRegreso) {
              id
              name
              tradicionalAlta
              totalAlta
              tradicionalBaja
              totalBaja
              isActive
              fecha
              image
              iconoWhite
              iconoBlack
              maletas
              maletasSm
              personas
              transmision
              lugar
          }
      }`,
      variables: obj
    }
  );
};
export const checkReserve = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query VerificarCategoria(
        $fechaSalida:DateTime!
        $fechaRegreso:DateTime!
        $categoria:ID!
        $reserva:ID!
    ) {
        verificarCategoria(
            fechaSalida: $fechaSalida
            fechaRegreso: $fechaRegreso
            categoria: $categoria
            reserva: $reserva
        )
    }
    `,
      variables: obj
    }
  );
};
export const getCategory = (id) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CategoriaPerId($id:ID!) {
            categoriaPerId(id: $id) {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                visible
                deleted
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
                descripcion
            }
        }`,
      variables: { id: id }
    }
  );
};

export const getReservedCarDates = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query VehiculosReservados(
            $salida: DateTime!
            $regreso: DateTime!
            $sucursal: Int!,
            $categoria:Int!
        ) {
            vehiculosReservados(
                fechaSalida: $salida
                fechaRegreso: $regreso
                sucursal: $sucursal,
                categoria:$categoria
            ) {
                id
                vehiculo {
                    id
                    noEcon
                    anno
                    transmision
                    vin
                    placa
                    isActive
                    numeroSerie
                    color
                    capacidadTanke
                    fechaAlta
                    estatus
                    fechaCredito
                    fechaCirculacion
                    fechaPoliza
                    files
                    categoria {
                        id
                        name
                        tradicionalAlta
                        totalAlta
                        tradicionalBaja
                        totalBaja
                        isActive
                        fecha
                        image
                        iconoWhite
                        iconoBlack
                        maletas
                        maletasSm
                        personas
                        transmision
                        lugar
                    }
                    version {
                        id
                        name
                        isActive
                        modelo {
                            id
                            name
                            isActive
                            marca {
                                id
                                name
                                isActive
                            }
                        }
                    }
                    titular {
                        id
                        name
                        isActive
                    }
                    aseguradora {
                        id
                        name
                        isActive
                    }
                    estado {
                        id
                        name
                        abreviatura
                        isActive
                    }
                    sucursal {
                        id
                        name
                        phone
                        contactPhone
                        address
                        postalCod
                        openHour
                        closeHour
                        isActive
                    }
                }
                reserva {
                    id
                    total
                    email
                    tipoCobertura
                    dias
                    serviciosExtras
                    tarifaDiaria
                    comentarios
                    fechaRenta
                    fechaFin
                    nombre
                    apellidos
                    metodoPago
                    telefono
                    edad
                    pagoId
                    origen
                    revisado
                    noReserva
                    fecha
                    estado
                    cantidad
                    categoria {
                        id
                        name
                        tradicionalAlta
                        totalAlta
                        tradicionalBaja
                        totalBaja
                        isActive
                        fecha
                        image
                        iconoWhite
                        iconoBlack
                        maletas
                        maletasSm
                        personas
                        transmision
                        lugar
                    }
                    sucursal {
                        id
                        name
                        phone
                        contactPhone
                        address
                        postalCod
                        openHour
                        closeHour
                        isActive
                    }
                    agente {
                        id
                        username
                        firstName
                        lastName
                        email
                        isActive
                        phone
                    }
                }
            }
        }`,
      variables: obj
    }
  );
};
export const getReservedCarDateUp = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query VehiculoReservadosSalida(
          $salida: DateTime!
          $sucursal: Int!,
          $categoria:Int!
      ) {
          vehiculoReservadosSalida(
              fechaSalida: $salida
              sucursal: $sucursal,
              categoria:$categoria
          ) {
              id
              vehiculo {
                  id
                  noEcon
                  anno
                  transmision
                  vin
                  placa
                  isActive
                  numeroSerie
                  color
                  capacidadTanke
                  fechaAlta
                  estatus
                  fechaCredito
                  fechaCirculacion
                  fechaPoliza
                  files
                  categoria {
                      id
                      name
                      tradicionalAlta
                      totalAlta
                      tradicionalBaja
                      totalBaja
                      isActive
                      fecha
                      image
                      iconoWhite
                      iconoBlack
                      maletas
                      maletasSm
                      personas
                      transmision
                      lugar
                  }
                  version {
                      id
                      name
                      isActive
                      modelo {
                          id
                          name
                          isActive
                          marca {
                              id
                              name
                              isActive
                          }
                      }
                  }
                  titular {
                      id
                      name
                      isActive
                  }
                  aseguradora {
                      id
                      name
                      isActive
                  }
                  estado {
                      id
                      name
                      abreviatura
                      isActive
                  }
                  sucursal {
                      id
                      name
                      phone
                      contactPhone
                      address
                      postalCod
                      openHour
                      closeHour
                      isActive
                  }
              }
              reserva {
                  id
                  total
                  email
                  tipoCobertura
                  dias
                  serviciosExtras
                  tarifaDiaria
                  comentarios
                  fechaRenta
                  fechaFin
                  nombre
                  metodoPago
                  apellidos
                  telefono
                  edad
                  pagoId
                  origen
                  revisado
                  noReserva
                  fecha
                  estado
                  cantidad
                  categoria {
                      id
                      name
                      tradicionalAlta
                      totalAlta
                      tradicionalBaja
                      totalBaja
                      isActive
                      fecha
                      image
                      iconoWhite
                      iconoBlack
                      maletas
                      maletasSm
                      personas
                      transmision
                      lugar
                  }
                  sucursal {
                      id
                      name
                      phone
                      contactPhone
                      address
                      postalCod
                      openHour
                      closeHour
                      isActive
                  }
                  agente {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      phone
                  }
              }
          }
      }`,
      variables: obj
    }
  );
};
export const getReservedCarDateDown = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query VehiculoReservadosRegreso(
          $regreso: DateTime!
          $sucursal: Int!,
          $categoria:Int!
      ) {
          vehiculoReservadosRegreso(
              fechaRegreso: $regreso
              sucursal: $sucursal,
              categoria:$categoria
          ) {
              id
              vehiculo {
                  id
                  noEcon
                  anno
                  transmision
                  vin
                  placa
                  isActive
                  numeroSerie
                  color
                  capacidadTanke
                  fechaAlta
                  estatus
                  fechaCredito
                  fechaCirculacion
                  fechaPoliza
                  files
                  categoria {
                      id
                      name
                      tradicionalAlta
                      totalAlta
                      tradicionalBaja
                      totalBaja
                      isActive
                      fecha
                      image
                      iconoWhite
                      iconoBlack
                      maletas
                      maletasSm
                      personas
                      transmision
                      lugar
                  }
                  version {
                      id
                      name
                      isActive
                      modelo {
                          id
                          name
                          isActive
                          marca {
                              id
                              name
                              isActive
                          }
                      }
                  }
                  titular {
                      id
                      name
                      isActive
                  }
                  aseguradora {
                      id
                      name
                      isActive
                  }
                  estado {
                      id
                      name
                      abreviatura
                      isActive
                  }
                  sucursal {
                      id
                      name
                      phone
                      contactPhone
                      address
                      postalCod
                      openHour
                      closeHour
                      isActive
                  }
              }
              reserva {
                  id
                  total
                  email
                  tipoCobertura
                  dias
                  serviciosExtras
                  tarifaDiaria
                  comentarios
                  fechaRenta
                  fechaFin
                  metodoPago
                  nombre
                  apellidos
                  telefono
                  edad
                  pagoId
                  origen
                  revisado
                  noReserva
                  fecha
                  estado
                  cantidad
                  categoria {
                      id
                      name
                      tradicionalAlta
                      totalAlta
                      tradicionalBaja
                      totalBaja
                      isActive
                      fecha
                      image
                      iconoWhite
                      iconoBlack
                      maletas
                      maletasSm
                      personas
                      transmision
                      lugar
                  }
                  sucursal {
                      id
                      name
                      phone
                      contactPhone
                      address
                      postalCod
                      openHour
                      closeHour
                      isActive
                  }
                  agente {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      phone
                  }
              }
          }
      }`,
      variables: obj
    }
  );
};
export const getReservedCarAll = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query AllReservados($categoria: Int!, $sucursal: Int!) {
          allReservados(categoria: $categoria, sucursal: $sucursal) {
              id
              vehiculo {
                  id
                  noEcon
                  anno
                  transmision
                  vin
                  placa
                  isActive
                  numeroSerie
                  color
                  capacidadTanke
                  fechaAlta
                  estatus
                  fechaCredito
                  fechaCirculacion
                  fechaPoliza
                  files
                  categoria {
                      id
                      name
                      tradicionalAlta
                      totalAlta
                      tradicionalBaja
                      totalBaja
                      isActive
                      fecha
                      image
                      iconoWhite
                      iconoBlack
                      maletas
                      maletasSm
                      personas
                      transmision
                      lugar
                  }
                  version {
                      id
                      name
                      isActive
                      modelo {
                          id
                          name
                          isActive
                          marca {
                              id
                              name
                              isActive
                          }
                      }
                  }
                  titular {
                      id
                      name
                      isActive
                  }
                  aseguradora {
                      id
                      name
                      isActive
                  }
                  estado {
                      id
                      name
                      abreviatura
                      isActive
                  }
                  sucursal {
                      id
                      name
                      phone
                      contactPhone
                      address
                      postalCod
                      openHour
                      closeHour
                      isActive
                  }
              }
              reserva {
                  id
                  total
                  email
                  tipoCobertura
                  dias
                  metodoPago
                  serviciosExtras
                  tarifaDiaria
                  comentarios
                  fechaRenta
                  fechaFin
                  nombre
                  apellidos
                  telefono
                  edad
                  pagoId
                  origen
                  revisado
                  noReserva
                  fecha
                  estado
                  cantidad
                  categoria {
                      id
                      name
                      tradicionalAlta
                      totalAlta
                      tradicionalBaja
                      totalBaja
                      isActive
                      fecha
                      image
                      iconoWhite
                      iconoBlack
                      maletas
                      maletasSm
                      personas
                      transmision
                      lugar
                  }
                  sucursal {
                      id
                      name
                      phone
                      contactPhone
                      address
                      postalCod
                      openHour
                      closeHour
                      isActive
                  }
                  agente {
                      id
                      username
                      firstName
                      lastName
                      email
                      isActive
                      phone
                  }
              }
          }
      }`,
      variables: obj
    }
  );
};
export const changeCar = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      mutation ChangeCar ($carId:ID!
        $reserveId:ID!) {
            changeCar(carId: $carId, reserveId: $reserveId) {
                success
                error
            }
        }`,
      variables: obj
    }
  );
};


export const createContrato = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          mutation CreateContrato(
            $canal:String!
            $agente: Int!,
            $checkin: Decimal!,
            $montoCombustible: Decimal!,
            $cliente: Int!,
            $comentarios: String!,
            $conductor: Int!,
            $descuento: Decimal!,
            $credito: Boolean!,
            $depositoEfectivo: Decimal!,
            $dias: Int!,
            $fechaRegreso: DateTime!,
            $fechaSalida: DateTime!,
            $precioHoraExtra: Decimal!,
            $sucursal: Int!,
            $tanque: Boolean!,
            $tarifaDiaria: Decimal!,
            $tarifaExtra: Decimal!,
            $deuda: Decimal!,
            $total: Decimal!,
            $vehiculo: Int!,
            $tipo:String!,
            $tarjeta:Int!,
            $servicios:[Int!],
            $reserva:String!,
            $tipoCobertura:String!,
            $seguroRc:Boolean!
            $cortesia:ID!
            
    ) {
        createContrato(
            agente: $agente
            checkin: $checkin
            descuento: $descuento
            cliente: $cliente
            montoCombustible: $montoCombustible
            comentarios: $comentarios
            conductor: $conductor
            credito: $credito
            depositoEfectivo: $depositoEfectivo
            dias: $dias
            fechaRegreso:$fechaRegreso
            fechaSalida: $fechaSalida
            precioHoraExtra: $precioHoraExtra
            sucursal:$sucursal
            tanque: $tanque
            tarifaDiaria:$tarifaDiaria
            tarifaExtra:$tarifaExtra
            deuda:$deuda
            total:$total
            vehiculo: $vehiculo
            canal:$canal
            tipo:$tipo
            tarjeta:$tarjeta
            servicios:$servicios
            reserva:$reserva
            tipoCobertura:$tipoCobertura
            seguroRc:$seguroRc
            cortesia:$cortesia
        ) {
            success
            created
            error
            contrato
        }
    }`,
      variables: obj
    }
  );
};
export const createCliente = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          mutation CreateCliente($apellidos:String!, $edad:Int!, $email:String!, $nombre:String!, $telefono:String!, $tipo:Int!
            $ciudad:String!, $codigo:String!, $estado:String!, $pais:String!, $licencia:Int!, $postal:String!, $telefonoEmergencia:String!, $direccion:String!) {
              createCliente(
                  apellidos: $apellidos
                  edad: $edad
                  email: $email
                  nombre: $nombre
                  telefono: $telefono
                  tipo: $tipo
                  ciudad: $ciudad
                  pais: $pais
                  estado: $estado
                  licencia: $licencia
                  postal: $postal
                  telefonoEmergencia: $telefonoEmergencia
                  direccion:$direccion
                  codigo:$codigo
              ) {
                  success
                  cliente {
                      id
                      nombre
                      apellidos
                      telefono
                      email
                      edad
                      isActive
                      direccion
                      postal
                      telefonoEmergencia
                  }
              }
          }`,
      variables: obj
    }
  );
};
export const createTarjeta = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          mutation CreateTarjeta($numero: String!, $autorizacion: String!, $vencimiento: Date!) {
            createTarjeta(numero: $numero, autorizacion: $autorizacion, vencimiento: $vencimiento) {
                success
                tarjeta {
                    id
                    numero
                    vencimiento
                    autorizacion
                }
            }
        }`,
      variables: obj
    }
  );
};
export const createLicencia = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          mutation CreateLicencia($pais:String!,$estado: String!, $numero: String!, $permanente: Boolean!, $vencimiento: Date!) {
            createLicencia(pais:$pais,estado: $estado, numero: $numero, permanente: $permanente, vencimiento: $vencimiento) {
                success
                licencia {
                    id
                    numero
                    vencimiento
                    estado
                    permanente
                }
            }
        }`,
      variables: obj
    }
  );
};
export const createDriver = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          mutation CreateExtraDriver(
            $apellidos:String!
            $cliente:ID!
            $edad:Int!
            $licencia:ID!
            $nombre:String!
        ) {
            createExtraDriver(
                apellidos: $apellidos
                cliente: $cliente
                edad: $edad
                licencia: $licencia
                nombre: $nombre
            ) {
                success
                conductor {
                    id
                }
            }
        }`,
      variables: obj
    }
  );
};

export const updateContract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          mutation UpdateContrato(
            $canal:String!
                $agente: Int!,
                $checkin: Decimal!,
                $cliente: Int!,
                $comentarios: String!,
                $conductor: Int!,
                $credito: Boolean!,
                $descuento: Decimal!,
                $depositoEfectivo: Decimal!,
                $dias: Int!,
                $fechaRegreso: DateTime!,
                $fechaSalida: DateTime!,
                $precioHoraExtra: Decimal!,
                $sucursal: Int!,
                $tanque: Boolean!,
                $tarifaDiaria: Decimal!,
                $montoCombustible: Decimal!,
                $tarifaExtra: Decimal!,
                $deuda: Decimal!,
                $vehiculo: Int!,
                $tipo:String!,
                $tarjeta:Int!,
                $servicios:[Int!],
                $id:Int!
                $cortesia:ID!
                $total:Decimal!
        ) {
            updateContrato(
                agente: $agente
                checkin: $checkin
                cliente: $cliente
                comentarios: $comentarios
                conductor: $conductor
                montoCombustible: $montoCombustible
                credito: $credito
                descuento: $descuento
                depositoEfectivo: $depositoEfectivo
                dias: $dias
                fechaRegreso:$fechaRegreso
                fechaSalida: $fechaSalida
                precioHoraExtra: $precioHoraExtra
                sucursal:$sucursal
                tanque: $tanque
                tarifaDiaria:$tarifaDiaria
                tarifaExtra:$tarifaExtra
                deuda:$deuda
                vehiculo: $vehiculo
                canal:$canal
                tipo:$tipo
                tarjeta:$tarjeta
                servicios:$servicios
                id:$id
                cortesia:$cortesia
                total:$total
            ) {
                success
                error
                contrato
            }
        }`,
      variables: obj
    }
  );
};
export const Services_by_contract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query ServicesByContract($id: ID!) {
            servicesByContract(id: $id) {
                id
                servicio {
                    name
                    precio
                }
            }
        }`,
      variables: { id: obj }
    }
  );
};
export const get_gas_price = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query Gasolina {
              gasolina
          }`
    }
  );
};
export const get_all_offices = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query AllSucursales {
            allSucursales {
                id
                name
                isActive
            }
        }`
    }
  );
};
export const get_Discounts = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query Cortesias {
            cortesias(name: "") {
                id
                name
                descuento
                isActive
            }
        }`
    }
  );
};
export const get_all_clients = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query AllCliente {
            allCliente {
                 id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
                carnet
                licenciaFile
                pasaporte
                tipo {
                    id
                    name
                    isActive
                }
                ciudad
                estado
                pais
                licencia {
                    id
                    numero
                    vencimiento
                    permanente
                    estado
                    pais
                }
                tarjeta {
                    id
                    numero
                    vencimiento
                    autorizacion
                }
            }
        }`
    }
  );
};
export const get_all_users = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query AllUsers {
            allUsers {
                id
                username
                firstName
                lastName
                email
                isActive
                phone
                groups {
                    id
                    name
                }
            }
        }`
    }
  );
};
export const get_all_client_types = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query AllTipocliente {
            allTipocliente {
                id
                name
                isActive
            }
        }`
    }
  );
};
export const get_all_categories = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query AllCategorias {
            allCategoriasAdmin {
                  id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
            }
        }`
    }
  );
};
export const get_all_branch = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query AllMarcas {
            allMarcas {
                id
                name
                isActive
            }
        }`
    }
  );
};
export const get_all_secures = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query AllSeguros {
            allSeguros(name: "") {
                id
                isActive
                name
                precioDiario
                seguroRcusa
            }
        }`
    }
  );
};
export const get_car_by_categorie = (id) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query FiltroVehiculos($categoria:Int!) {
            filtroVehiculos(categoria: $categoria) {
                id
                noEcon
                anno
                transmision
                vin
                placa
                isActive
                numeroSerie
                color
                capacidadTanke
                fechaAlta
                estatus
                fechaCredito
                fechaCirculacion
                fechaPoliza
                files
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                version {
                    id
                    name
                    isActive
                    modelo {
                        id
                        name
                        isActive
                        marca {
                            id
                            name
                            isActive
                        }
                    }
                }
                titular {
                    id
                    name
                    isActive
                }
                aseguradora {
                    id
                    name
                    isActive
                }
                estado {
                    id
                    name
                    abreviatura
                    isActive
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                    ciudad {
                        id
                        name
                        isActive
                    }
                }
            }
        }`,
      variables: {
        categoria: id
      }
    }
  );
};
export const update_categorie = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          mutation UpdateCategoria($id: ID!, $name:String!, $maletas:Decimal!, $maletasSm:Decimal!, $personas:Decimal!,
            $transmision:String!,$lugar:String!, $descripcion:String!) {
              updateCategoria(id: $id, name:$name, transmision:$transmision, maletasSm:$maletasSm,
            lugar:$lugar, personas:$personas, maletas:$maletas, descripcion:$descripcion) {
                  success
                  errors
              }
          }`,
      variables: obj
    }
  );
};
export const get_list_categorie = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
          query ($name:String!){
            categoriaPerName(name:$name){
              id
                  name
                  tradicionalAlta
                  totalAlta
                  visible
                  tradicionalBaja
                  totalBaja
                  isActive
                  fecha
                  image
                  iconoWhite
                  iconoBlack
                  maletas
                  maletasSm
                  personas
                  transmision
                  lugar
                  descripcion
            }
          }`,
      variables: obj
    }
  );
};